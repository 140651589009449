<template>
  <div>
    <v-container fluid class="py-8">
      <Notify ref="myNotify"></Notify>
      <v-card color="light">
        <v-app-bar color="light" class="border-left-primary">
          <v-toolbar-title>{{ $t("order.Orders") }}</v-toolbar-title>
        </v-app-bar>
        <!-- <Table :items="orders" :loading="loading"></Table> -->
        <Table
          @change_pre_page="ChangePrePage"
          @get_data_paginate="handlePageChange"
          @action="action"
          :loading="loading"
          :btns="btns"
          :headers="headers"
          :items="orders"
          :paginate="paginate"
          :btn_table="btn_table"
        ></Table>
      </v-card>
    </v-container>
  </div>
</template>
<script>
// import Table from "./TableOrder.vue";
import Notify from "../Components/New/Notify.vue";
import Table from "../Components/PaginationTable.vue";
import CustomerService from "../../services/customer.service";
export default {
  name: "order-Page",
  components: {
    Table,
    Notify,
  },
  data() {
    return {
      btn_table: {
        excel: false,
      },
      paginate: {
        page: 1,
        itemsPerPage: 0,
        search: "",
        total: 0,
      },
      loading: true,
      message: "",
      modal_data: {
        size: "500px",
        title: "",
        dialog: false,
      },
      btns: [
        {
          type: "icon",
          text: "inquiry",
          color: "bg-gradient-info",
          icon: "mdi-eye",
        },
      ],
      headers: [
        // { text: "ID", value: "id", align: "center" },
        {
          text: this.$i18n.t("cycle.Cycle"),
          value: "cycles.name_lang",
          align: "center",
        },
        {
          text: this.$i18n.t("order.status"),
          value: "chip",
          align: "center",
        },
        {
          text: this.$i18n.t("inquiry.Inqieries"),
          value: "btns",
          align: "center",
        },
      ],
      orders: [],
    };
  },

  methods: {
    get_orders(reset = false, type = null) {
      console.log("order1");
      const formData = new FormData();
      if (type == "pre_page") {
        formData.append("paginate", this.paginate.itemsPerPage);
      }
      return CustomerService.get_order_customer(
        this.paginate.page,
        formData
      ).then(
        (response) => {
          // console.log(response);
          this.orders = response.data.orders.data;
          this.paginate.total = response.data.orders.total;
          this.paginate.itemsPerPage = parseInt(response.data.orders.per_page);
          this.loading = false;
        },
        (error) => {
          this.loading = false;
          console.log(error);
          this.$store.dispatch("setNotify", {
            msg: this.$i18n.t("general.there is problem"),
            type: "Danger",
          });
        }
      );
    },
    ChangePrePage(page) {
      this.paginate.itemsPerPage = parseInt(page);
      this.paginate.page = 1;
      this.get_orders(false, "pre_page");
    },
    handlePageChange(page) {
      console.log(page);
      this.paginate.page = page;
      this.get_orders();
    },
    action(id, name, item) {
      if (name == "inquiry") {
        this.inquiry(id, item);
      }
    },
    inquiry(id, item) {
      console.log(item);
      if (item.status_id == 4) {
        this.message = this.$i18n.t("this item is rejected");
        this.$store.dispatch("form/setNotify", {
          msg: this.message,
          type: "Danger",
        });
      } else {
        this.$router.push({ path: `/orders/${id.toString()}/inqueries` });
      }
    },
  },

  mounted() {
    this.get_orders();
    document.title = this.$i18n.t("order.Orders");
  },
};
</script>
